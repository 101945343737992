<template>
  <div
    class="d-flex py-3"
    :class="{
      'cursor-pointer': !expanded,
      'align-items-md-center': !alt,
    }"
    v-resize="onResize"
  >
    <div class="pr-3">
      <WpPicture
        ref="image"
        :image="image"
        :ratio="1"
        class="wet-avatar bg-white border"
        :class="{
          'border-primary': area,
        }"
        size="medium"
        @load="imageLoad"
      />
    </div>
    <div
      class="d-flex flex-fill flex-column"
      :class="{
        'flex-md-row align-items-md-center': !alt,
      }"
    >
      <div
        class="flex-fill mr-md-3 d-flex justify-content-between align-items-center"
        :class="{
          'mb-md-0 mb-3': !alt,
          'mb-3': alt && expanded,
        }"
      >
        <div
          class="d-flex"
          :class="{
            'justify-content-between': !area
          }"
        >
          <span class="mr-3" :class="{ 'font-weight-bold': area }">{{ title }}</span>
        </div>
        <InputSwitch v-if="alt" v-model="expanded"/>
      </div>
      <div v-if="!alt || (expanded || !!area)" class="row no-gutters">
        <div
          class="col-12"
          :class="{
            'col-sm-6 col-md-6 mb-3': alt,
            'col-md-4 mb-3 mb-md-0': !alt,
          }"
        >
          <InputNumber
            primary
            :placeholder="cropSelection.area_label"
            :label="cropSelection.area_label"
            :right="true"
            :min="validationRules.min_area"
            :max="validationRules.max_area"
            :step="1"
            :append="ha"
            class="crop-selection-item-area-input"
            :class="{
              'mr-0 mr-md-4': !alt,
              'mr-0 mr-sm-3': alt,
            }"
            hideDetails="auto"
            clearIfMin
            :accent="accent"
            arrows
            v-model="$v.areaInput.$model"
            :errors="areaErrors"
            :decimals="0"
          />
        </div>
        <div v-if="alt" class="col-12 col-sm-6 col-md-6 mb-3">
          <InputNumber
            primary
            :placeholder="cropSelection['per-hectare_label']"
            :label="cropSelection['per-hectare_label']"
            :right="true"
            :min="validationRules.min_crop_value_per_hectar"
            :max="validationRules.max_crop_value_per_hectar"
            :step="1"
            :append="currency"
            class="ml-0 ml-sm-3"
            hideDetails="auto"
            :accent="accent"
            arrows
            v-model="$v.perHectarInput.$model"
            :errors="perHectarErrors"
            :decimals="0"
          />
        </div>
        <div
          class="col-12"
          :class="{
            'col-md-12': alt,
            'col-md-8': !alt,
          }"
        >
          <component
            :is="bundle ? 'PeriodAlt2' : 'PeriodAlt'"
            :startDate.sync="periodStartDate"
            :endDate.sync="periodEndDate"
            :startText="cropSelection[`start_date_${alt ? 'alt_' : ''}label`]"
            :endText="cropSelection[`end_date_${alt ? 'alt_' : ''}label`]"
            :validationRules="validationRules"
            :calendLang="lang"
            :defaultStartDate="periodDefaultStartDate"
            :defaultEndDate="periodDefaultEndDate"
            :primary="!!area"
            :startLimitInterval="startLimitInterval"
            :postponementInterval="postponementInterval"
            :nextYearStartLimitInterval="nextYearStartLimitInterval"
            :postponementSkipped="postponementSkipped"
          >
            <transition name="collapse">
              <p v-if="startDateError"
                 class="small text-primary line-height-small pt-2 pl-3"
              >{{ startDateError }}</p>
            </transition>
          </component>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import ValidationItem from '@/models/validation-item';
import {
  required,
  minValue,
  maxValue,
} from 'vuelidate/lib/validators';
import common from '@/mixins/common';

export default {
  name: 'CropSelectionItem',
  mixins: [common],
  components: {
    InputNumber: () => import(/* webpackChunkName: "InputNumber" */ '@/components/ui/inputNumberUi.vue'),
    WpPicture: () => import(/* webpackChunkName: "wp-picture" */ '@/components/ui/wpPictureUi.vue'),
    PeriodAlt: () => import(/* webpackChunkName: "PeriodAlt" */ '@/components/sections/periodAlt.vue'),
    InputSwitch: () => import(/* webpackChunkName: "InputSwitch" */ '@/components/ui/inputSwitchUi.vue'),
    PeriodAlt2: () => import(/* webpackChunkName: "PeriodAlt2" */ '@/components/sections/periodAlt2.vue'),
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    image: {
      type: Object,
      default: () => {},
    },
    area: {
      type: Number,
    },
    startDate: {
      type: String,
      default: null,
    },
    endDate: {
      type: String,
      default: null,
    },
    crop: {
      type: String,
      default: null,
    },
    defaultStartDate: {
      type: String,
      default: null,
    },
    defaultEndDate: {
      type: String,
      default: null,
    },
    accent: {
      type: Boolean,
      default: false,
    },
    alt: Boolean,
    perHectar: {
      type: Number,
      default: null,
    },
    defaultCropValuePerHectar: {
      type: Number,
    },
    defaultArea: {
      type: Number,
    },
    bundle: Boolean,
  },
  data() {
    return {
      startDateLimit: 15,
      isMobile: false,
      zeroTime: {
        h: 0,
        m: 0,
        s: 0,
        ms: 0,
      },
      expanded: false,
      areaInput: null,
      perHectarInput: null,
    };
  },
  validations() {
    return {
      areaInput: {
        minValue: minValue(this.validationRules.min_area),
        maxValue: maxValue(this.validationRules.max_area),
      },
      perHectarInput: {
        // required if only area is not null
        required: (val) => (this.areaInput === null && true) || required(val),
        minValue: minValue(this.validationRules.min_crop_value_per_hectar),
        maxValue: maxValue(this.validationRules.max_crop_value_per_hectar),
      },
    };
  },
  computed: {
    ...mapState(['strings', 'lang', 'settings']),
    ha() {
      return this.strings.commonApp?.unit.ha;
    },
    currency() {
      return this.strings.commonApp?.unit.currency_zt;
    },
    cropSelection() {
      return this.strings.cropSelection || {};
    },
    drought() {
      return this.strings.drought || {};
    },
    validationRules() {
      return this.settings.calendar?.[this.crop] || new ValidationItem({});
    },
    postponementSkipped() {
      return this.postponementInterval.min.isAfter(this.startLimitInterval.max);
    },
    startDateError() {
      return this.postponementSkipped
        ? this.drought.only_next_year_available.replace('%s', this.$moment(this.startDate)
          .format('YYYY')) : null;
    },
    minContractStartPostponement() {
      return this.validationRules.min_contract_start_postponement;
    },
    maxContractStartPostponement() {
      return this.validationRules.max_contract_start_postponement;
    },
    startLimitInterval() {
      return {
        min: this.$moment(this.defaultStartDate).subtract(this.startDateLimit, 'days'),
        max: this.$moment(this.defaultStartDate).add(this.startDateLimit, 'days'),
      };
    },
    nextYearStartLimitInterval() {
      return {
        min: this.$moment(this.defaultStartDate).subtract(this.startDateLimit, 'days').add(1, 'years'),
        max: this.$moment(this.defaultStartDate).add(this.startDateLimit, 'days').add(1, 'years'),
      };
    },
    postponementInterval() {
      return {
        min: this.$moment.today().add(this.minContractStartPostponement, 'days'),
        max: this.$moment.today().add(this.maxContractStartPostponement, 'days'),
      };
    },
    maxStartDate() {
      return this.nextYearStartLimitInterval.max.isAfter(this.postponementInterval.max)
        ? this.postponementInterval.max : this.nextYearStartLimitInterval.max;
    },
    periodStartDate: {
      get() {
        const startDate = this.$moment(this.startDate, 'YYYY-MM-DD', true);
        let periodStartDate;

        if (this.$moment(this.startDate).diff(this.defaultStartDate, 'days') > 0) {
          periodStartDate = this.$moment(this.startDate);
        } else {
          periodStartDate = this.postponementInterval.min.isAfter(this.startLimitInterval.max)
            ? this.maxStartDate : startDate;
        }

        return periodStartDate.isValid() ? periodStartDate.toDate() : null;
      },
      set(val) {
        this.$emit('update:startDate', this.$moment(val).format('YYYY-MM-DD'));
      },
    },
    periodEndDate: {
      get() {
        const date = this.$moment(this.endDate, 'YYYY-MM-DD', true);
        return date.isValid() ? date.toDate() : null;
      },
      set(val) {
        this.$emit('update:endDate', this.$moment(val).format('YYYY-MM-DD'));
      },
    },
    periodDefaultStartDate() {
      const date = this.$moment(this.defaultStartDate, 'YYYY-MM-DD', true);
      return date.isValid() ? date.toDate() : null;
    },
    periodDefaultEndDate() {
      const date = this.$moment(this.defaultEndDate, 'YYYY-MM-DD', true);
      return date.isValid() ? date.toDate() : null;
    },
    areaErrors() {
      const error = this.format(
        this.cropSelection.error_area_tpl,
        this.validationRules.min_area,
        this.validationRules.max_area,
      );
      return this.$v.areaInput.$error ? [error] : [];
    },
    perHectarErrors() {
      const error = this.format(
        this.cropSelection.error_per_hectar_tpl,
        this.validationRules.min_crop_value_per_hectar,
        this.validationRules.max_crop_value_per_hectar,
      );
      return this.$v.perHectarInput.$error ? [error] : [];
    },
  },
  watch: {
    area: {
      immediate: true,
      handler(val) {
        if (val === null) return;
        this.areaInput = val;
      },
    },
    perHectar: {
      immediate: true,
      handler(val) {
        if (val === null) return;
        this.perHectarInput = val;
      },
    },
    areaInput(val) {
      this.$emit('update:area', val);
    },
    perHectarInput(val) {
      this.$emit('update:perHectar', val);
    },
    expanded(val) {
      if (val) {
        // set defaults area and perHectar
        if (this.areaInput === null) this.areaInput = this.defaultArea;
        if (this.perHectarInput === null) this.perHectarInput = this.defaultCropValuePerHectar;
      } else {
        // clear area value
        this.areaInput = null;
        this.perHectarInput = null;
      }
    },
    '$v.$invalid': {
      immediate: true,
      handler(val) {
        this.$emit('invalid', val);
      },
    },
  },
  mounted() {
    this.onResize();
  },
  methods: {
    imageLoad() {
      this.$refs.image.$el.classList.toggle('empty-img', false);
    },
    onResize() {
      this.calcIsMobile();
    },
    calcIsMobile() {
      this.isMobile = Number(window.innerWidth) < 768;
    },
    validate() {
      this.$v.$touch();
      return !this.$v.$anyError;
    },
  },
};
</script>
<style lang="scss">
  .wet-avatar {
    display: block;
    overflow: hidden;
    width: 5rem;
    height: 5rem;
    border-radius: var(--border-radius);
  }
</style>
