var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}],staticClass:"d-flex py-3",class:{
    'cursor-pointer': !_vm.expanded,
    'align-items-md-center': !_vm.alt,
  }},[_c('div',{staticClass:"pr-3"},[_c('WpPicture',{ref:"image",staticClass:"wet-avatar bg-white border",class:{
        'border-primary': _vm.area,
      },attrs:{"image":_vm.image,"ratio":1,"size":"medium"},on:{"load":_vm.imageLoad}})],1),_c('div',{staticClass:"d-flex flex-fill flex-column",class:{
      'flex-md-row align-items-md-center': !_vm.alt,
    }},[_c('div',{staticClass:"flex-fill mr-md-3 d-flex justify-content-between align-items-center",class:{
        'mb-md-0 mb-3': !_vm.alt,
        'mb-3': _vm.alt && _vm.expanded,
      }},[_c('div',{staticClass:"d-flex",class:{
          'justify-content-between': !_vm.area
        }},[_c('span',{staticClass:"mr-3",class:{ 'font-weight-bold': _vm.area }},[_vm._v(_vm._s(_vm.title))])]),(_vm.alt)?_c('InputSwitch',{model:{value:(_vm.expanded),callback:function ($$v) {_vm.expanded=$$v},expression:"expanded"}}):_vm._e()],1),(!_vm.alt || (_vm.expanded || !!_vm.area))?_c('div',{staticClass:"row no-gutters"},[_c('div',{staticClass:"col-12",class:{
          'col-sm-6 col-md-6 mb-3': _vm.alt,
          'col-md-4 mb-3 mb-md-0': !_vm.alt,
        }},[_c('InputNumber',{staticClass:"crop-selection-item-area-input",class:{
            'mr-0 mr-md-4': !_vm.alt,
            'mr-0 mr-sm-3': _vm.alt,
          },attrs:{"primary":"","placeholder":_vm.cropSelection.area_label,"label":_vm.cropSelection.area_label,"right":true,"min":_vm.validationRules.min_area,"max":_vm.validationRules.max_area,"step":1,"append":_vm.ha,"hideDetails":"auto","clearIfMin":"","accent":_vm.accent,"arrows":"","errors":_vm.areaErrors,"decimals":0},model:{value:(_vm.$v.areaInput.$model),callback:function ($$v) {_vm.$set(_vm.$v.areaInput, "$model", $$v)},expression:"$v.areaInput.$model"}})],1),(_vm.alt)?_c('div',{staticClass:"col-12 col-sm-6 col-md-6 mb-3"},[_c('InputNumber',{staticClass:"ml-0 ml-sm-3",attrs:{"primary":"","placeholder":_vm.cropSelection['per-hectare_label'],"label":_vm.cropSelection['per-hectare_label'],"right":true,"min":_vm.validationRules.min_crop_value_per_hectar,"max":_vm.validationRules.max_crop_value_per_hectar,"step":1,"append":_vm.currency,"hideDetails":"auto","accent":_vm.accent,"arrows":"","errors":_vm.perHectarErrors,"decimals":0},model:{value:(_vm.$v.perHectarInput.$model),callback:function ($$v) {_vm.$set(_vm.$v.perHectarInput, "$model", $$v)},expression:"$v.perHectarInput.$model"}})],1):_vm._e(),_c('div',{staticClass:"col-12",class:{
          'col-md-12': _vm.alt,
          'col-md-8': !_vm.alt,
        }},[_c(_vm.bundle ? 'PeriodAlt2' : 'PeriodAlt',{tag:"component",attrs:{"startDate":_vm.periodStartDate,"endDate":_vm.periodEndDate,"startText":_vm.cropSelection[`start_date_${_vm.alt ? 'alt_' : ''}label`],"endText":_vm.cropSelection[`end_date_${_vm.alt ? 'alt_' : ''}label`],"validationRules":_vm.validationRules,"calendLang":_vm.lang,"defaultStartDate":_vm.periodDefaultStartDate,"defaultEndDate":_vm.periodDefaultEndDate,"primary":!!_vm.area,"startLimitInterval":_vm.startLimitInterval,"postponementInterval":_vm.postponementInterval,"nextYearStartLimitInterval":_vm.nextYearStartLimitInterval,"postponementSkipped":_vm.postponementSkipped},on:{"update:startDate":function($event){_vm.periodStartDate=$event},"update:start-date":function($event){_vm.periodStartDate=$event},"update:endDate":function($event){_vm.periodEndDate=$event},"update:end-date":function($event){_vm.periodEndDate=$event}}},[_c('transition',{attrs:{"name":"collapse"}},[(_vm.startDateError)?_c('p',{staticClass:"small text-primary line-height-small pt-2 pl-3"},[_vm._v(_vm._s(_vm.startDateError))]):_vm._e()])],1)],1)]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }